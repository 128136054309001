import request from "@/utils/request";
/**
 * 获取组织信息 GET
 * @param {*} id
 */
export function getOrganizationInfo(id) {
  return request({
    url: "/DataCenter/Organization/GetInfo?id=" + id,
    method: "get"
  });
}
/**
 * 获取组织树 GET
 * @param {*} params
 */
export function getOrgTree(params) {
  return request({
    url: "/DataCenter/organization/GetOrgTree",
    method: "get",
    params
  });
}

/**
 * 获取组织列表 GET
 * @param {*} params
 */
export function getOrganizationAllList(params) {
  return request({
    url: "/DataCenter/Organization/GetAllList",
    method: "get",
    params
  });
}
/**
 * 获取组织分页列表 GET
 * @param {*} params
 */
export function getOrganizationPageList(params) {
  return request({
    url: "/DataCenter/Organization/GetPageList",
    method: "get",
    params
  });
}
/**
 * 新增组织 POST
 * @param {*} data
 */
export function insertOrganization(data) {
  return request({
    url: "/DataCenter/Organization/Insert",
    method: "post",
    data
  });
}
/**
 * 批量新增组织 POST
 * @param {*} data
 */
export function batchInsertOrganization(data) {
  return request({
    url: "/DataCenter/Organization/BatchInsert",
    method: "post",
    data
  });
}
/**
 * 修改组织 POST
 * @param {*} data
 */
export function updateOrganization(data) {
  return request({
    url: "/DataCenter/Organization/Update",
    method: "post",
    data
  });
}
/**
 * 删除组织 POST
 * @param {*} id
 */
export function deleteOrganization(id) {
  return request({
    url: "/DataCenter/Organization/Delete?id=" + id,
    method: "post"
  });
}
/**
 * 批量删除组织 POST
 * @param {*} data
 */
export function batchOrganizationDelete(data) {
  return request({
    url: "/DataCenter/Organization/BatchDelete",
    method: "post",
    data
  });
}

/**
 * 获取组织树 GET
 * @param {*} params
 */
export function getTree(params) {
  return request({
    url: "/DataCenter/Organization/GetTree",
    method: "get",
    params
  });
}
/**
 * 获取老师列表 GET
 * @param {*} params
 */
export function getOrgTeacherList(params) {
  return request({
    url: "/DataCenter/organization/GetOrgTeacherList",
    method: "get",
    params
  });
}
/**
 * 获取学生列表 GET
 * @param {*} params
 */
export function getOrgStudentList(params) {
  return request({
    url: "/DataCenter/organization/GetOrgStudentList",
    method: "get",
    params
  });
}
/**
 * 获取企业导师列表 GET
 * @param {*} params
 */
export function getOrgEnterpriseTeacherList(params) {
  return request({
    url: "/DataCenter/organization/GetOrgEnterpriseTeacherList",
    method: "get",
    params
  });
}

/**
 *新增老师 POST
 * @param {*} data
 */
export function insertTeacher(data) {
  return request({
    url: "/DataCenter/organization/InsertTeacher",
    method: "post",
    data
  });
}
/**
 *新增学生 POST
 * @param {*} data
 */
export function insertStudent(data) {
  return request({
    url: "/DataCenter/organization/InsertStudent",
    method: "post",
    data
  });
}
/**
 *批量新增老师 POST
 * @param {*} data
 */
export function batchInsertTeacher(data) {
  return request({
    url: "/DataCenter/organization/BatchInsertTeacher",
    method: "post",
    data
  });
}
/**
 *批量新增学生 POST
 * @param {*} data
 */
export function batchInsertStudent(data) {
  return request({
    url: "/DataCenter/organization/BatchInsertStudent",
    method: "post",
    data
  });
}
/**
 *新增企业导师 POST
 * @param {*} data
 */
export function insertEnterpriseTeacher(data) {
  return request({
    url: "/DataCenter/organization/InsertEnterpriseTeacher",
    method: "post",
    data
  });
}
/**
 *新增老师关系 POST
 * @param {*} data
 */
export function insertOrgTeacher(data) {
  return request({
    url: "/DataCenter/organization/InsertOrgTeacher",
    method: "post",
    data
  });
}
/**
 *新增学生关系 POST
 * @param {*} data
 */
export function insertOrgStudent(data) {
  return request({
    url: "/DataCenter/organization/InsertOrgStudent",
    method: "post",
    data
  });
}
/**
 *批量删除教师关系 POST
 * @param {*} params
 */
export function batchDeleteOrgTeacher(params) {
  return request({
    url: "/DataCenter/organization/BatchDeleteOrgTeacher",
    method: "post",
    params
  });
}
/**
 *批量删除教师关系 POST
 * @param {*} params
 */
export function batchDeleteOrgStudent(params) {
  return request({
    url: "/DataCenter/organization/BatchDeleteOrgStudent",
    method: "post",
    params
  });
}
/**
 *批量删除企业教师关系 POST
 * @param {*} params
 */
export function batchDeleteOrgEnterpriseTeacher(params) {
  return request({
    url: "/DataCenter/organization/BatchDeleteOrgEnterpriseTeacher",
    method: "post",
    params
  });
}

/**
 *注册教师用户 POST
 * @param {*} params
 */
export function registerTeacherUser(params) {
  return request({
    url: "/DataCenter/organization/RegisterTeacherUser",
    method: "post",
    params
  });
}
/**
 *注册学生用户 POST
 * @param {*} params
 */
export function registerStudentUser(params) {
  return request({
    url: "/DataCenter/organization/RegisterStudentUser",
    method: "post",
    params
  });
}
/**
 *注册企业导师用户 POST
 * @param {*} params
 */
export function registerEnterpriseTeacherUser(params) {
  return request({
    url: "/DataCenter/organization/RegisterEnterpriseTeacherUser",
    method: "post",
    params
  });
}

/**
 *用户禁用和激活 POST
 * @param {*} params
 */
export function changeUserStatus(params) {
  return request({
    url: "/DataCenter/organization/ChangeUserStatus",
    method: "post",
    params
  });
}
/**
 * 获取节点的最大实体级别 params = id
 * @param {*} params
 */
export function getMaxOrgEntityLevel(params) {
  return request({
    url: "/DataCenter/organization/GetMaxOrgEntityLevel",
    method: "get",
    params
  });
}
/**
 * 获取节点的最大实体级别 params = id
 * @param {*} params
 */
export function getLastParentNodeWithEntity(params) {
  return request({
    url: "/DataCenter/organization/GetLastParentNodeWithEntity",
    method: "get",
    params
  });
}
